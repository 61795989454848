'use client'
import { Box, Button, Stack } from '@mantine/core'
import Image from 'next/image'
import React from 'react'
import curtains from 'public/curtains.jpg'
import classes from './not-found-template.module.css'
import { IconArrowLeft } from '@tabler/icons-react'
import Link from 'next/link'

export const NotFoundTemplate = () => {
  return (
    <Box w="100vw" h="100vh" pos="relative">
      <Image
        src={curtains}
        fill={true}
        alt="curtains"
        draggable={false}
        placeholder="blur"
        quality={100}
        style={{
          filter: 'grayscale(72%)',
          objectFit: 'cover',
        }}
      />
      <Box className={classes.overlay}>
        <Box className={classes.content}>
          <Stack align="center" gap="xl">
            <Box component="h1" className={classes.title}>
              Страница не найдена
            </Box>
            <Button
              size="md"
              color="dark"
              leftSection={<IconArrowLeft strokeWidth={1.5} size={22} />}
              component={Link}
              href="/"
            >
              На главную
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}
